import { Card, Col, Row, Typography, Select, Avatar } from "antd";
import moment from "moment";
import { useState } from "react";
import { useGetCryptosQuery } from "../services/crypto-api";
import { useGetCryptoNewsQuery } from "../services/crypto-news-api";
import Loader from "./Loader";

const { Text, Title } = Typography;
const { Option } = Select;

const demoImage = 'http://coinrevolution.com/wp-content/uploads/2020/06/cryptonews.jpg';

const News = ({ simplified }) => {
    const count = simplified ? 6 : 12;
    const [newsCategory, setNewsCategory] = useState('Cryptocyrrency');
    // const { data: cryptoNews, isFetching } = useGetCryptoNewsQuery({ newsCategory: 'Cryptocurrency', count });
    const { data: cryptoNews, isFetching } = useGetCryptoNewsQuery({ newsCategory, count });
    const { data } = useGetCryptosQuery(100);


    console.log(cryptoNews);

    if (isFetching) return <Loader />;

    return (
        <Row gutter={[24, 24]}>
            {!simplified && (
                <Col span={24}>
                    <Select 
                        showSearch 
                        className="select-news" 
                        placeholder="Select a Crypto" 
                        optionFilterProp="children" 
                        onChange={(value) => setNewsCategory(value)} 
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        <Option value="Cryptocurrency">Crypto Currency</Option>
                        {data?.data?.coins.map((coin) => <Option value={coin.name}>{coin.name}</Option>)}
                    </Select>
                </Col>
            )}
            {cryptoNews.value.map((news, i) => (
                <Col xs={24} sm={12} lg={8} key={i}>
                    <Card hoverable className="news-card">
                        <a href={news.url} target="_blank" rel="noreferrer">
                            <div className="news-image-container">
                                <Title className="news-title" level={4}>{news.name}</Title>
                                <img style={{ maxWidth: '200px', maxHeight: '100px'}} src={news?.image?.thumbnail?.contentUrl || demoImage} alt="news" />
                            </div>
                            <p>
                                {news.description.length > 100 
                                    ? `${news.description.substring(0, 100)}...`
                                    : news.description 
                                }
                            </p>

                            <div className="provider-container">
                                <div>
                                <Avatar src={news.provider[0]?.image?.thumbnail?.contentUrl || demoImage} alt="" />
                                <Text className="provider-name">{news.provider[0]?.name}</Text>
                                </div>
                                <Text>{moment(news.datePublished).startOf('ss').fromNow()}</Text>
                            </div>
                        </a>
                    </Card>
                </Col>
            ))}
        </Row>
    );
}
  
export default News;